import React from 'react';
import { graphql } from 'gatsby';
import styled from "styled-components"
import { GatsbyImage } from 'gatsby-plugin-image'
import GlobalStyle from '@/styles/GlobalStyles'
import { ColorPaletteMain as Colors } from '@/styles/ColorPalette';
import { Title, Label, Paragraph, DividerSubTitle, SubTitle } from '@/components/TextComponents/textStyles'
import NotFound from './404'

const TeamMember = ({ data }) => {
  const _data = data.directus.clinic[0].person[0]
  const dividerText = data.directus.TextGlobals.team_cv_divider_headline

  // data transformer
  const person = {
    image: _data?.image?.imageFile?.childImageSharp?.gatsbyImageData ?? '',
    jobTitle: _data?.job_title ?? '',
    name: _data?.name ?? '',
    jobDescription: _data?.job_description ?? '',
    personDescription: _data?.person_description,
    cv: _data?.cv?.map((cvItem) => ({
      id: cvItem.id ?? '',
      timeFrame: cvItem.time_frame ?? '',
      itemDescription: cvItem.description ?? ''
    })) ?? ''
  }

  if (!_data) {
    return <NotFound />
  }

  return (
    <main>
      <GlobalStyle />
      <HeaderImage image={person.image} alt="" />
      <Content>
        <IntroWrapper>
          <SubTitleHighlight level="2">{person.jobTitle}</SubTitleHighlight>
          <Title as="h4" level="4" margin="3px 0">
            {person.name}
          </Title>
          <LabelWrapper level="1">{person.jobDescription}</LabelWrapper>
        </IntroWrapper>
        {!!person.personDescription && <PersonDescription>{person.personDescription.split('\n').map((line) => {
          return <React.Fragment>
            <p>
              {line}
            </p>
            </React.Fragment>
        })}</PersonDescription>}
        {!!person.cv && <DividerSubTitle>{dividerText}</DividerSubTitle>}
        <CVContentWrapper>
          {!!person.cv &&
            person.cv.map((cvItem) => {
              return (
                <CVItemWrapper key={cvItem.id}>
                  <SubTitle level="2">{cvItem.timeFrame}</SubTitle>
                  <Paragraph level="1" isSmall>
                    {cvItem.itemDescription}
                  </Paragraph>
                </CVItemWrapper>
              )
            })}
        </CVContentWrapper>
      </Content>
    </main>
  )
}


const PersonDescription = styled.div`
  font-size: 13px;
  margin: 0px 0 12px;
  padding: 0 24px;
  text-align: center;
`

const HeaderImage = styled(GatsbyImage)`
  border-radius: 4px;
  width: 100%;
  min-height: 188px;
  margin-bottom: 30px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const IntroWrapper = styled.div`
  margin: 0 auto 22px;
  text-align: center;
`

const SubTitleHighlight = styled(SubTitle)`
  padding: 3px 0;
  color: ${Colors.accentColors.blue4};
`

const LabelWrapper = styled(Label)`
  margin-top: 16px;
  color: ${Colors.primaryColors.ink2};
`

const CVContentWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 22px 0;
  padding-left: 40px;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 10px;
    bottom: 0;
    content: '';
    height: 100%;
    width: 2px;
    background: linear-gradient(135deg, #00ACE5 10%, #C3FDED 100%);
  }
`

const CVItemWrapper = styled.div`
  padding-bottom: 24px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: -37px;
    background-image: url('../../img/GradientBullet.svg');
    background-repeat: no-repeat;
    width: 8px;
    height: 8px;
    border: 4px solid white;
    border-radius: 50%;
  }

  &:first-child:after {
    position: absolute;
    content: '';
    left: -38px;
    top: -1px;
    width: 16px;
    height: 16px;
    border: 1px dashed ${Colors.accentColors.blue4};
    border-radius: 50%;
  }

  &:last-child {
    padding-bottom: 4px;
  }
`

export default TeamMember

export const query = graphql`
  query TeamMember($clinic: String, $id: String) {
    directus {
      TextGlobals {
        team_cv_divider_headline
      }
      clinic(filter: {clinic_id: {_eq: $clinic}}) {
        clinic_id
        person(filter: {id: {_eq: $id}}) {
          id
          name
          job_title
          job_description
          person_description
          cv
          image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(height: 400, width: 400, placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
              }
            }
          }
        }
      }
    }
  }
`
